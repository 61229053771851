<template>
    <div>
        <div class="about-us-top np-padding">
            <div class="about-us-in">
                <div class="about-left">
                    <h1>About Us</h1>
                    <p>Seattle Choice Transport  is a family owned business that was founded in 2015 by Thierno Bah. Our driving force is excellence, therefore, we always strive to outdo our customers' expectations.</p>
                </div>
                <div class="about-right" :style="{ backgroundImage: 'url(' + require(`@/assets/images/about-us-top.png`) + ')'}"></div>
            </div>
        </div>
        <div class="about-design">
            <div class="container">
                <div class="about-design-main">
                   <div class="about-design-left">
                        <h2>Designed to deliver</h2>
                        <p>The safety and comfort of our drivers—the heart of the business— is of primary importance to us, that is why we plough money into equipment that would make their experience better. Over the years, we have been able to garner the trust of our customers because we are known to always deliver.</p>
                        <p>We deliver truckloads to all 48 states in the US; you can trust us enough to cast all your loads on us.</p>
                    </div>
                    <div class="about-design-right">
                        <div class="about-right-box">
                            <img src="../assets/images/eye.png">
                            <h4>Vision</h4>
                            <p>Our Vision is to become a world class customer focused trucking and logistics company.</p>
                        </div>
                        <div class="about-right-box">
                            <img src="../assets/images/flag-alt.png">
                            <h4>Mission</h4>
                            <p>We at Seattle Choice hold ourselves to a standard of excellence. We work as a team to exceed our customers expectation and the Safety of our team is our top priority.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="meet-the-team-section">
            <div class="container">
                <div class="section-title">
                    <h2>Meet the Team</h2>				
                </div>
                <div class="meet-team-inner">
                    <div class="team-membar">
                        <img src="../assets/images/team-1.jpg">
                        <h4>Lorem Ipsum</h4>
                        <p>Lorem Ipsum dolor</p>
                    </div>
                    <div class="team-membar">
                        <img src="../assets/images/team-2.jpg">
                        <h4>Lorem Ipsum</h4>
                        <p>Lorem Ipsum dolor</p>
                    </div>
                    <div class="team-membar">
                        <img src="../assets/images/team-3.jpg">
                        <h4>Lorem Ipsum</h4>
                        <p>Lorem Ipsum dolor</p>
                    </div>
                    <div class="team-membar">
                        <img src="../assets/images/team-4.jpg">
                        <h4>Lorem Ipsum</h4>
                        <p>Lorem Ipsum dolor</p>
                    </div>
                </div>
            </div>
        </div> -->
    </div>
</template>
<script>
export default {
  name: 'About',
}
</script>